import * as React from "react";
import { useTranslation } from "react-i18next";
import Hero, { IHeroProps, headerStyle } from "src/components/Organisms/Hero/hero";
import Subscription from "src/components/Organisms/Subscription/subscription";

const heroProps: IHeroProps = {
  style: headerStyle(require("src/components/Organisms/images/banner/crm.jpg")),
  header: "CapsuleCRM",
  subLine:
      "A CRM system to streamline your work and grow your business."
};

export default (props: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <Hero {...heroProps} />
      <div className="container-fluid section-padding-tb96">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner">
                <img className="responsive" src={require("src/components/Organisms/images/crm-partnership.png")} alt="Capsule CRM x Centre O" />
              </div>

              <p>{t('crm.headline')}</p>

              <ul>
                <li>{t('crm.features_1')}</li>
                <li>{t('crm.features_2')}</li>
                <li>{t('crm.features_3')}</li>
                <li>{t('crm.features_4')}</li>
                <li>{t('crm.features_5')}</li>
                <li>{t('crm.features_6')}</li>
                <li>{t('crm.features_7')}</li>
              </ul>

              <p>{t('crm.ending_1')}</p>
              <p>{t('crm.ending_2')}</p>

              <Subscription />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
